import React from 'react'
import './MenuToggler.css'

function MenuToggler({ open, toggleMenu }) {
	return (
		<div
			onClick={() => toggleMenu()}
			id='menu-btn'
			className={open ? 'open menu-btn' : 'menu-btn'}
		>
			<div className='menu-btn-burger'></div>
		</div>
	)
}

export default MenuToggler
