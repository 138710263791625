import React from 'react'
import {Link} from 'react-router-dom'

function ProjectCard({ data, scrollToTop }) {
	const {
		id,
		name,
		projectThumbnail,
		shortDescription,
		technologiesUsed,
		githubUrl,
		liveUrl,
	} = data
	console.log(technologiesUsed)
	console.log('Project Card')
	console.log(data)

	return (
		<>
			<div className='project-screenshot-container'>
				<img
					src={projectThumbnail}
					className='project-card-screenshot'
					alt='project screenshot'
				/>
				<div className='project-card-screenshot-overlay'>
					<a
						className='project-link'
						href={liveUrl}
						target='_blank'
						alt='url to the live site'
						rel='noreferrer'
					>
						<i className='fa-solid fa-globe'></i>live page
					</a>
					<a
						className='project-link'
						href={githubUrl}
						target='_blank'
						alt='url to the live site'
						rel='noreferrer'
					>
						<i className='fa-solid fa-code'></i>code
					</a>
				</div>
			</div>
			<h3 onClick={()=>scrollToTop()}><Link to={`/${data.id}`}>{name}</Link></h3>

			<ul className='project-technologies'>
				{technologiesUsed.map((e, i) => (
					<li key={i}>{e}</li>
				))}
			</ul>
			<p className='seeMore' onClick={()=>scrollToTop()}><Link to={`/${data.id}`}>See more...</Link></p>
		</>
	)
}

export default ProjectCard
