import React from 'react'
import { TechnologiesInputList } from '../TechnologiesInputList'
function TechnologiesComp() {
	return (
		<section className='technology-section'>
			<h2>Technologies I work with</h2>
			<ul className='technology-list'>
				{TechnologiesInputList.map((e, i) => (
					<li key={i}>
							<img className='technology-icon' src={e.icon} alt={e.name} />
							<p className='technology-name'>{e.name}</p>
					</li>
				))}
			</ul>
		</section>
	)
}

export default TechnologiesComp
