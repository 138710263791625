import React from 'react'
import { useParams } from 'react-router-dom'
import { ProjectsInputList } from '../ProjectsInputList'
function Project() {
	const { projectID } = useParams()

	const thisProject = ProjectsInputList.find(e => e.id === projectID)

	console.log(thisProject)
	return (
		<section className='content-container project-container'>
			<div className='col'>
			<h1 className='project-title'>{thisProject.name}</h1>
			<h4 className='project-subtitle'>{thisProject.shortDescription}</h4>

			<img
				src={thisProject.projectThumbnail}
				className='project-screenshot'
				alt='project screenshot'
			/>	
			<div className='project-text-container'>		
				<p className='project-description'>{thisProject.longDescription}</p>
				<p>{thisProject.longTechnologyUsed}</p>
			</div>

			<div className='link-container'>
				<a href={thisProject.githubUrl} alt='project repository on github'><i className='fa-brands fa-github'></i>Github respository</a>
				<a href={thisProject.liveUrl} alt='live demo'><i className="fa-solid fa-link"></i> Live Demo</a>
			</div>
			
			</div>
			<img
				src={thisProject.projectThumbnail}
				className='project-screenshot-side'
				alt='project screenshot'
			/>	


		</section>
	)
}

export default Project
