import React from 'react'
import './EducationComp.css'
import { EducationData } from '../EducationData'

function EducationComp() {
	return (
		<section className='education-section'>
			<h2>Education</h2>
			<ul className='education_tree_flex'>
				{EducationData.map((e, i) => (
					<li key={i} className='education-item'>
						<h4>{e.education}</h4>
						<p className='sub'>{e.university}</p>
						<p className='location'>{e.location}</p>
					</li>
				))}
			</ul>
		</section>
	)
}

export default EducationComp
