import React, { useState, useContext } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { ScrollSectionContext } from '../context/ScrollSectionContext'
import MenuToggler from './MenuToggler'
function Header({homepage}) {
	const {
		scrollToSection,
		heroSection,
		aboutMeSection,
		codingFormationSection,
		educationSection,
		technologiesSection,
		contactSection,
		projectSection,
	} = useContext(ScrollSectionContext)

	const [open, setOpen] = useState(false)

	const toggleMenu = () => {
		setOpen((prevState) => !prevState)
	}

	const scrollToTop = () => {
		window.scrollTo(0, 0)
	}

	return (
		<div className={open ? 'open header' : 'header'}>
			<nav className='nav-bar'>
				<p onClick={()=>scrollToTop()}className='logo'>
					<Link to='/'>
						Z<span className='mirror'>B</span>Dev
					</Link>
				</p>
				{homepage ? 
				<>
				<ul className='nav-list'>
				<li
						onClick={() => {
							scrollToSection(aboutMeSection)
							setOpen(false)
						}}
					>
						About me
					</li>
					<li
						onClick={() => {
							scrollToSection(projectSection)
							setOpen(false)
						}}>
						Projects
					</li>
					
					<li
						onClick={() => {
							scrollToSection(contactSection)
							setOpen(false)
						}}
					>
						Contact
					</li>
				</ul>
				<MenuToggler toggleMenu={toggleMenu} open={open} />
			</> : null}
			</nav>
		</div>
	)
}

export default Header
