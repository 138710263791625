export const TechnologiesInputList = [
	{
		name: 'Responsive design',
		icon: './responsive.png',
		url: '',
	},
	{
		name: 'HTML',
		icon: './html.png',
		url: '',
	},
	{
		name: 'CSS',
		icon: './css.png',
		url: '',
	},
	{
		name: 'JavaScript',
		icon: './js.png',
		url: '',
	},
	{
		name: 'React',
		icon: './react.png',
		url: '',
	},
	{
		name: 'Firebase',
		icon: './firebase.png',
		url: '',
	},
	{
		name: 'Figma',
		icon: './figma.png',
		url: '',
	},
	{
		name: 'Adobe Photoshop',
		icon: './ps.png',
		url: '',
	},
	{
		name: 'Material UI',
		icon: './mui.png',
		url: '',
	},
	{
		name: 'NodeJs',
		icon: './nodejs.png',
		url: '',
	},
	{
		name: 'MongoDB',
		icon: './mongodb.png',
		url: '',
	},
	{
		name: 'Express',
		icon: './express.png',
		url: '',
	}

]
