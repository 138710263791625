import React from 'react'

function LanguagesComp() {
  return (
		<section className='languages-section'>
			<h2>Languages I speak</h2>
			<ul className='languages-list'>
				<li>English</li>
                <li>German</li>
                <li>Spanish</li>
                <li>Hungarian</li>
			</ul>
		</section>
	)
}

export default LanguagesComp