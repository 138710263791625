import React, { useContext, useRef } from 'react'
import './App.css'
import { Route, Routes } from 'react-router-dom'
import { ThemeContext } from './context/ThemeContext'
import Header from './components/Header'
import Project from './pages/Project'
import Home from './pages/Home'
import Footer from './components/FooterIconComp'
import {
	ScrollSectionContextProvider,
	ScrollSectionContext,
} from './context/ScrollSectionContext'

function App() {
	const heroSection = useRef(null)
	const contactSection = useRef(null)
	const aboutMeSection = useRef(null)
	const projectSection = useRef(null)

	const scrollToSection = (elementRef) => {
		window.scrollTo({
			top: elementRef.current.offsetTop,
			behavior: 'smooth',
		})
	}

	const { dark } = useContext(ThemeContext)
	return (
		<div className={dark ? 'dark wrapper' : ' light wrapper'}>
			<Routes>
				<Route
					exact path='/'
					element={
						<>
						<Header
							contactSection={contactSection}
							heroSection={heroSection}
							projectSection={projectSection}
							homepage={true}
						/>
						<Home
							contactSection={contactSection}
							heroSection={heroSection}
							projectSection={projectSection}
							scrollToSection={scrollToSection}
						/>
						</>
					}
				/>
				<Route 
					path='/:projectID' element={
						<>
							<Header
								contactSection={contactSection}
								heroSection={heroSection}
								projectSection={projectSection}
								homepage={false}
							/>
							<Project /> 
						</>
					}/>
			</Routes>
			<Footer />
		</div>
	)
}

export default App
