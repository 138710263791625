import React from 'react'
import { CodingFormationData } from '../CodingFormationData'

function CodingFormation() {
	return (
		<section className='coding-formation-section'>
			<h2>Certificates and courses</h2>
			<ul>
				{CodingFormationData.map((e, i) => (
					<li key={i}>
						<h4 className='headline'>{e.headline}</h4>
						<p className='sub'>{e.university}</p>
					</li>
				))}
			</ul>
		</section>
	)
}

export default CodingFormation
