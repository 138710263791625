import React from 'react'
import avatarDesktop from '../img/avatar.png'
import avatarMobile from '../img/avatar-square.png'

function Hero({ scrollToContact, scrollToProjects }) {
	return (
		<section className='hero-section'>
			<div className='hero-text-container'>
				<h1>Hi!</h1>
				<p className='hero_first'>
					I am{' '}
					<span className='accent name'>
						<span className='mirror'>B</span>ence Zambo
					</span>
				</p>
				<p className='subheading'>
					an aspiring <span className='accent'>Frontend Developer</span>
				</p>

				<p className='hero-phrase typewriter'>I code and build things!</p>
			</div>

			<div className='hero-img-container'>
				<picture>
					<source
						id='hero-img-desktop'
						media='(min-width: 600px)'
						srcSet={avatarDesktop}
						alt='hero image'
					/>
					<img
						id='hero-img-mobile'
						className='avatar'
						src={avatarDesktop}
						alt='hero image'
					/>
				</picture>
			</div>
			<div className='btn-container'>
				<button className='btn contact-btn pulsate' onClick={scrollToContact}>
					Contact me!
				</button>
				<button className='btn project-btn pulsate' onClick={scrollToProjects}>
					See my work!
				</button>
			</div>
		</section>
	)
}

export default Hero
