import React, { useState, createContext } from 'react'

const ThemeContext = createContext()

function ThemeContextProvider({ children }) {
	const [dark, setDark] = useState(true)

	const toggleDark = () => {
		setDark((prevState) => !prevState)
	}

	return (
		<ThemeContext.Provider value={{ dark, toggleDark }}>
			{children}
		</ThemeContext.Provider>
	)
}

export { ThemeContext, ThemeContextProvider }
