export const ProjectsInputList = [
	{
		id: 'yg-sfddsfs',
		name: 'Beer-Commerce App',
		projectThumbnail: 'https://firebasestorage.googleapis.com/v0/b/frontendportfolio-b0a92.appspot.com/o/beer_commerce.png?alt=media&token=c131af55-902c-4879-ab99-733dfcf507c8',
		shortDescription: 'A mock e-commerce site I built to practice and showcase the skills I have learned with React Router v6 and Redux.',
		longDescription: 'A full-stack application using MongoDB, Express, and NodeJS to build the backend and ChakraUI for the frontend. The app-wide states are managed by Redux, and for data fetching and sending requests, I have used the React Router action and loader features. Users can register, add their own products, and purchase new products. Furthermore, it also stores their previous orders.',
		longTechnologyUsed: 'I built this application using the MERN Stack, in which the React Library provides the frontend of the application, and the backend is built using NodeJS with Express. The app is fully responsive and was designed following the mobile-first approach.',
		liveUrl: 'https://mern-beer-ecommerce.web.app/',
		githubUrl: 'https://github.com/zambobence/mern-beer-ecommerce',
		technologiesUsed: ['ChakraUI', 'Redux', 'React', 'React Router v6', 'NodeJs', 'Express', 'MongoDB']
	},
	{
		id: 'yg-eofy0el',
		name: 'iLost App',
		projectThumbnail: 'https://firebasestorage.googleapis.com/v0/b/frontendportfolio-b0a92.appspot.com/o/ilost-app.png?alt=media&token=91c27025-7427-4e4e-91be-42f521eeb25e',
		shortDescription:
			'This app was inspired when I found a key on the street, and I was wondering how I could find out who had lost it and return it to them. Not having found any options, I decided to create one.',
		longDescription: 'In this app, registered users can post the items they have lost or found, providing essential information such as type, color, date, and location within a certain radius of where it was found. Everyone can browse and filter through the posted items based on their location, type, and color. If you are a registered user, you can also see the contact details of the person who posted it.',
		longTechnologyUsed: 'I built this application using the MERN Stack, in which React Library provides the frontend of the application, and the backend is built using NodeJS with Express. The application is using the Google Maps API to embed a map and for geolocation. The app is fully responsive and was designed following the mobile-first approach.',
		liveUrl: 'https://ilost-app-v2.web.app/browse',
		githubUrl: 'https://github.com/zambobence/mern_ilost_app_frontend',
		technologiesUsed: ['React', 'NodeJs', 'Express', 'MongoDB']
	},
	{
		id: 'eE7R03Qza8',
		name: 'ToDo App',
		projectThumbnail: 'https://firebasestorage.googleapis.com/v0/b/frontendportfolio-b0a92.appspot.com/o/frontendmentor-todo-app.png?alt=media&token=4d93e383-b9d5-4694-9695-729b80ed7083',
		shortDescription:
			'A full-stack ToDo App created based on the design of FrontendMentor.',
		longDescription: 'This is my first full-stack application written using the MongoDB, Express, NodeJs, and React stack. It features both light and dark modes.',
		longTechnologyUsed: 'The app uses NodeJS Express for the backend, and the data is stored using MongoDB. It is fully responsive and was designed using the mobile-first approach.',
		liveUrl: 'https://fm-to-do-app.firebaseapp.com/login',
		githubUrl: 'https://github.com/zambobence/MERN_Todo_App_Frontend',
		technologiesUsed: ['React', 'NodeJS', 'Express', 'MongoDB'],
	},
	{
		id: 'kjzf5zjlji',
		name: 'What 2 Cook Recipe App',
		projectThumbnail: 'https://firebasestorage.googleapis.com/v0/b/frontendportfolio-b0a92.appspot.com/o/recipe_img.png?alt=media&token=5860bcad-c8c2-4c04-8e25-4413f8e9b6ab',
		shortDescription:
			'An application for recipes that utilizes the Spoonacular API, enabling users to search for recipes by name, receive ingredients-based recipe suggestions, and save their favorite recipes.',
		longDescription: '',
		longTechnologyUsed: 'This application is communicating with the Spoonacular API to retrieve recipes based on certain criteria. I was following the mobile-first-design philosophy, and it is fully responsive.', 
		liveUrl: 'https://zambobence.github.io/what-2-cook/',
		githubUrl: 'https://github.com/zambobence/what-2-cook',
		technologiesUsed: ['HTML', 'CSS', 'React', 'APIs'],
	},

	{
		id: '0sdf2x345-1',
		name: 'Dictionary App',
		projectThumbnail: 'https://firebasestorage.googleapis.com/v0/b/frontendportfolio-b0a92.appspot.com/o/dictionary-app.jpg?alt=media&token=cc258b07-f8a0-4d4a-b0e6-e91532f6e348',
		shortDescription:
			'A web application integrating the Web Dictionary API.',
		longDescription: 'The application is communicating with the Web Dictionary API, fetching data of a word, and displaying it to the user. It has the feature to switch between dark and light mode; furthermore, users can toggle between different font styles within the app. Dark mode is automatically toggled if the user has the preference for dark mode set in the browser. Design was provided by FrontendMentor.',
		longTechnologyUsed: 'The application was built with React; it uses the Web Dictionary API to fetch data. It was styled with mobile-first design and is fully responsive.', 
		liveUrl: 'https://zambobence.github.io/fm-dictionary-app/',
		githubUrl: 'https://github.com/zambobence/fm-dictionary-app',
		technologiesUsed: ['HTML', 'CSS', 'React', 'APIs'],
	},
	{
		id: 'P8yo5IoMBW',
		name: 'Quizzical App',
		projectThumbnail: 'https://firebasestorage.googleapis.com/v0/b/frontendportfolio-b0a92.appspot.com/o/react-quiz-app.png?alt=media&token=a0aa4a10-d0e9-4faa-a91e-e8d74b058948',
		shortDescription:
			'A Quiz App using Trivia API.',
		longDescription: 'The original version of the Quiz App was a solo project in the Frontend Developer Career Path. However, I have decided to add a feature that allows customization of topics, difficulty levels, and question types.',
		longTechnologyUsed: 'This project was built using the React library, and it also fetches questions from the OpenTrivia API.',
		liveUrl: 'https://react-quiz-app-33ad8.firebaseapp.com/',
		githubUrl: 'https://github.com/zambobence/React-Quiz-App',
		technologiesUsed: ['HTML', 'CSS', 'React'],
	},
	{
		id: 'S59CozdyZr',
		name: 'Clock Layout',
		projectThumbnail: 'https://firebasestorage.googleapis.com/v0/b/frontendportfolio-b0a92.appspot.com/o/frontendmentor-clock-layout.png?alt=media&token=a293fe8e-2217-46e2-a817-f00a67632d6c',
		shortDescription:
			'This is a clock layout that can be used as a start page for browsers.',
		longDescription: 'The background and styling of the page dynamically change based on the time of day. It uses the Worldtime API to fetch the user\'s timezone based on their IP address. Additionally, the Quotable API is used to fetch random quotes on each visit or refresh.',
		longTechnologyUsed: 'The layout was coded using vanilla JavaScript and plain CSS, following a mobile-first approach, and is fully responsive across devices.',
		liveUrl: 'https://zambobence.github.io/FrontendMentor_Clock_layout/',
		githubUrl: 'https://github.com/zambobence/FrontendMentor_Clock_layout',
		technologiesUsed: ['HTML', 'CSS', 'Javascript'],
	},
	{
		id: 'MhD1kbBPQg',
		name: 'Room Layout',
		projectThumbnail: 'https://firebasestorage.googleapis.com/v0/b/frontendportfolio-b0a92.appspot.com/o/frontendmentor-room-layout.jpg?alt=media&token=b6eb8dbb-239a-4f3e-b972-d7fbe291c958',
		shortDescription:
			'A landing page based on the design of FrontendMentor.',
		longDescription: '',
		longTechnologyUsed: 'The layout was coded using vanilla Javascript and plain CSS. The layout was coded with a mobile-first approach and is fully responsive across devices.',
		liveUrl: 'https://zambobence.github.io/FrontendMentor_Room_homepage/',
		githubUrl:
			'https://github.com/zambobence/FrontendMentor_Room_homepage',
		technologiesUsed: ['HTML', 'CSS', 'Javascript'],
	}	
];
