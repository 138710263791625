import React from 'react'
import { ContactData } from '../ContactData'

function FooterIconComp() {
	return (
		<footer>
			<ul className='footer-icon-list'>
				{ContactData.map((e, i) => (
					<li key={i}>
						<a href={e.link} alt={e.name}>
							{e.icon}
						</a>
					</li>
				))}
			</ul>
		</footer>
	)
}

export default FooterIconComp
