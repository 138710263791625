import React, { useRef, useContext } from 'react'
import { Zoom, Fade } from 'react-reveal'
import Hero from '../components/Hero'
import TechnologiesComp from '../components/TechnologiesComp'
import ContactComp from '../components/ContactComp'
import ProjectListComp from '../components/ProjectListComp'
import EducationComp from '../components/EducationComp'
import CodingFormation from '../components/CodingFormation'
import AboutMeComp from '../components/AboutMeComp'
import { ScrollSectionContext } from '../context/ScrollSectionContext'
import ScrollToNextSection from '../components/ScrollToNextSection'
import LanguagesComp from '../components/LanguagesComp'

function Home() {
	const {
		scrollToSection,
		heroSection,
		aboutMeSection,
		codingFormationSection,
		educationSection,
		languagesSection,
		technologiesSection,
		contactSection,
		projectSection,
	} = useContext(ScrollSectionContext)

	const scrollToTop = () => {
		window.scrollTo(0, 0)
	}

	return (
		<div className='content-container'>
			<Hero
				scrollToContact={() => scrollToSection(contactSection)}
				scrollToProjects={() => scrollToSection(projectSection)}
			/>
			<ScrollToNextSection
				nextSectionName={'About me'}
				scrollToNext={() => scrollToSection(aboutMeSection)}
			/>


			<div ref={aboutMeSection}>
				<Fade cascade>
					<AboutMeComp />
					<ScrollToNextSection
						nextSectionName={'Technologies I use'}
						scrollToNext={() => scrollToSection(technologiesSection)}
					/>
				</Fade>
			</div>

			<div ref={technologiesSection}>
				<Fade>
					<TechnologiesComp />
					<ScrollToNextSection
						nextSectionName={'My Projects'}
						scrollToNext={() => scrollToSection(projectSection)}
				/>
				</Fade>
			</div>
			
			<div ref={projectSection}>
					<ProjectListComp scrollToTop={scrollToTop}/>
					<ScrollToNextSection
						nextSectionName={'Certificates and courses'}
						scrollToNext={() => scrollToSection(codingFormationSection)}
				/>
			</div>
			

			<div ref={codingFormationSection}>
				<Fade cascade>
					<CodingFormation />
					<ScrollToNextSection
						nextSectionName={'Education'}
						scrollToNext={() => scrollToSection(educationSection)}
				/>
				</Fade>
			</div>

			<div ref={educationSection}>
				<Fade>
					<EducationComp />
					<ScrollToNextSection
						nextSectionName={'Languages I speak'}
						scrollToNext={() => scrollToSection(languagesSection)}
					/>
				</Fade>
			</div>
			
			<div ref={languagesSection}>
				<Fade>
					<LanguagesComp />
					<ScrollToNextSection
						nextSectionName={'Contact'}
						scrollToNext={() => scrollToSection(contactSection)}
				/>
				</Fade>
			</div>
			
			<div ref={contactSection}>
				<Fade>
					<ContactComp />
				</Fade>
			</div>
			
		</div>
	)
}

export default Home
