export const EducationData = [
	{
		education: 'B.A. in International Studies',
		university: 'Eötvös Lóránd University',
		location: 'Budapest, Hungary',
	},
	{
		education: 'M.A. in International Relations',
		university: 'Andrássy University Budapest',
		location: 'Budapest, Hungary',
	},
	{
		education: 'M.A. in Governance and Public Policy',
		university: 'University Passau',
		location: 'Passau, Germany',
	},
	{
		education: 'Diplomado en investigación social de migraciones',
		university: 'Universidad Santo Tomas',
		location: 'Bogotá, Colombia',
	},
]
