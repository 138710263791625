import React, {useRef, useContext} from 'react'
import {ScrollSectionContext} from '../context/ScrollSectionContext'

function AboutMeComp() {

	const {
		scrollToSection,
		heroSection,
		aboutMeSection,
		codingFormationSection,
		educationSection,
		languagesSection,
		technologiesSection,
		contactSection,
		projectSection,
	} = useContext(ScrollSectionContext)

	return (
		<section className='aboutme-section'>
			<h2>About me</h2>
			<p className='introduction'>
			My name is Bence I&apos;m a web developer with a strong interest in learning new technologies and pushing the boundaries of what&apos;s possible. 
			I&apos;m always looking to think outside of the box and come up with creative solutions to problems. 
			I&apos;m not afraid to walk the extra mile to get things done and I love exploring different programming languages. 
			I speak English German and Spanish and am willing to relocate to start my journey as a junior web developer.
			<p className='callForAction' onClick={()=>scrollToSection(projectSection)}>Check out my portfolio to see what I&apos;ve been working on!</p>
			</p>
		</section>
	)
}

export default AboutMeComp
