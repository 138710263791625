import React from 'react'
import { ProjectsInputList } from '../ProjectsInputList'
import ProjectCard from './ProjectCard'

function ProjectListComp() {

	const scrollToTop = () => {
		window.scrollTo(0, 0)
	}

	return (
		<section className='project-list-section'>
			<h2>Projects</h2>
			<ul className='project-list'>
				{ProjectsInputList.map((e, i) => (
					<li key={i} className='project-card'>
						<ProjectCard data={e} scrollToTop={scrollToTop} />
					</li>
				))}
			</ul>
		</section>
	)
}

export default ProjectListComp
