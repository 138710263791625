export const CodingFormationData = [
	{
		headline: 'React, NodeJS, Express & MongoDB - The MERN Fullstack Guide',
		university: 'Academind GmbH',
	},
	{
		headline: 'NodeJS - The Complete Guide (incl. Deno.js, REST APIs, GraphQL)',
		university: 'Academind GmbH',
	},
	{
		headline: 'Meta Front-End Developer Specialization',
		university: 'Meta - Coursera',
	},
	{
		headline: 'Frontend Developer Career Path',
		university: 'Scrimba Oslo Norway',
	},
	{
		headline: 'AWS Fundamentals Specialization',
		university: 'AWS - Coursera',
	},
	{
		headline: 'Introduction to MongoDB',
		university: 'MongoDB University - Coursera'
	},
	{
		headline: 'Developing Back-End Apps with Node.js and Express',
		university: 'IBM - Coursera',
	},
	{
		headline: 'Google IT Support Specialization',
		university: 'Google - Coursera',
	},
	{
		headline: 'Programming for Everybody (Getting Started with Python)',
		university: 'University of Michigan Coursera',
	},
	{
		headline: 'CSS Grid & Flexbox for Responsive Layouts',
		university: 'FrontendMasters',
	},
]
