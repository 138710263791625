import React from 'react'
import ReactDOM from 'react-dom/client'
import './index.css'
import App from './App'
import { ThemeContextProvider } from './context/ThemeContext'
import { BrowserRouter as Router } from 'react-router-dom'
import { ScrollSectionContextProvider } from './context/ScrollSectionContext'

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
	<Router>
		<ScrollSectionContextProvider>
			<ThemeContextProvider>
				<App />
			</ThemeContextProvider>
		</ScrollSectionContextProvider>
	</Router>
)
