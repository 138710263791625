import React, { useContext } from 'react'
import { ScrollSectionContext } from '../context/ScrollSectionContext'

function ScrollToNextSection({ nextSectionName, scrollToNext }) {
	return (
		<div className='scrollToNextComponent' onClick={scrollToNext}>
			<h4>{nextSectionName}</h4>
			<i className='floating arrow fa-solid fa-chevron-down'></i>
		</div>
	)
}

export default ScrollToNextSection
