export const ContactData = [
	{
		icon: <i className='fa-solid fa-envelope'></i>,
		name: 'email',
		link: 'mailto:zambobence23@gmail.com',
		label: 'zambobence23@gmail.com',
	},
	/*
    {
        icon: <i class="fa-solid fa-phone"></i>,
        name:'phone',
        link: 'tel:+36303000262',
        label: '+36 30 3000 262'
    },
    */
	{
		icon: <i className='fa-brands fa-linkedin'></i>,
		name: 'linkedin',
		link: 'https://www.linkedin.com/in/bence-zambo-4b3b76153/',
		label: 'Bence Zambo',
	},
	{
		icon: <i className='fa-brands fa-github'></i>,
		name: 'github',
		link: 'https://github.com/zambobence',
		label: 'zambobence',
	},
]
