import React, { useState, createContext, useRef } from 'react'

const ScrollSectionContext = createContext()

function ScrollSectionContextProvider({ children }) {
	const heroSection = useRef(null)
	const aboutMeSection = useRef(null)
	const codingFormationSection = useRef(null)
	const educationSection = useRef(null)
	const languagesSection = useRef(null)
	const technologiesSection = useRef(null)
	const contactSection = useRef(null)
	const projectSection = useRef(null)

	const scrollToSection = (elementRef) => {
		window.scrollTo({
			top: elementRef.current.offsetTop,
			behavior: 'smooth',
		})
	}

	return (
		<ScrollSectionContext.Provider
			value={{
				scrollToSection,
				heroSection,
				aboutMeSection,
				codingFormationSection,
				educationSection,
				languagesSection,
				technologiesSection,
				contactSection,
				projectSection,
			}}
		>
			{children}
		</ScrollSectionContext.Provider>
	)
}

export { ScrollSectionContext, ScrollSectionContextProvider }
