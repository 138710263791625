import React, { useState, useRef } from 'react'
import emailjs from 'emailjs-com'
import Swal from 'sweetalert2'

function ContactFormComponent() {
	const YOUR_SERVICE_ID = process.env.REACT_APP_YOUR_SERVICE_ID
	const YOUR_TEMPLATE_ID = process.env.REACT_APP_YOUR_TEMPLATE_ID
	const YOUR_PUBLIC_KEY = process.env.REACT_APP_YOUR_PUBLIC_KEY

	const form = useRef()

	const sendEmail = (e) => {
		e.preventDefault()
		console.log(form.current)
		emailjs
			.sendForm(
				YOUR_SERVICE_ID,
				YOUR_TEMPLATE_ID,
				form.current,
				YOUR_PUBLIC_KEY
			)
			.then((result) => {
				console.log(result.text)
				Swal.fire({
					icon: 'success',
					title: 'Message Sent Successfully',
				})
				e.target.reset()
			})
			.catch((error) => {
				console.log(error.text)
				Swal.fire({
					icon: 'error',
					title: 'Ooops, something went wrong',
					text: e.text,
				})
			})
	}

	return (
		<>
			<h3>Or send me an email below!</h3>

			<form className='form-container' ref={form} onSubmit={sendEmail}>
				<label>Name</label>
				<input required type='text' name='name' />
				<label>Email</label>
				<input required type='email' name='email' />
				<label>Message</label>
				<textarea required name='message' />
				<button className='btn'>Send</button>
			</form>
		</>
	)
}

export default ContactFormComponent
