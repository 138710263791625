import React from 'react'
import { ContactData } from '../ContactData'
import ContactFormComponent from './ContactFormComponent'

function ContactComp() {
	return (
		<section className='contact-section'>
			<h2>You can contact me at:</h2>
			<ul className='contact-list'>
				{ContactData.map((e, i) => (
					<li key={i}>
						<a href={e.link} alt={e.name}>
							{e.icon} {e.label}
						</a>
					</li>
				))}
			</ul>
			<ContactFormComponent />
		</section>
	)
}

export default ContactComp
